import clsx from 'clsx';
import React from 'react';

import { ReactComponent as LoaderImg } from '../../../../public/icons/loaderAnimation.svg';
import { EButtonSize, EButtonTheme, EButtonVariant } from '../../atoms/Button';
import Button from '../../atoms/Button/Button';
import { MediaAboutCard } from '../../atoms/MediaAboutCard';
import { TNewsCardProps } from '../../atoms/NewsCard/NewsCard';
import { Container } from '../../sections/Container';

type TMediaCardRow = {
  newsPosts: TNewsCardProps[];
  isLoading?: boolean;
  buttons?: React.ComponentProps<typeof Button>[];
};

export const MediaAboutUsRow: React.FC<TMediaCardRow> = ({
  newsPosts,
  isLoading,
  buttons,
}) => {
  const buttonLineStyles = 'h-[2px] rounded-sm text-control-200 block w-full';

  return (
    <section>
      <Container>
        <div className='flex flex-col'>
          {isLoading ? (
            <div className='h-full w-full bg-surface-100'>
              <LoaderImg className='h-[708px] w-full md:h-[522px]' />
            </div>
          ) : (
            <>
              {!!newsPosts.length && (
                <div
                  className={clsx(
                    'font-playfair flex flex-row flex-wrap gap-2 tablet:grid tablet:grid-cols-2 tablet:gap-4 desktop:grid-cols-3',
                  )}
                >
                  {newsPosts.map((item, index) => (
                    <MediaAboutCard
                      className={clsx({
                        'hidden tablet:flex': index === 4,
                        'tablet:col-span-2 desktop:col-span-1': index === 2,
                        'desktop:row-span-2 desktop:prose-h3:text-5xl desktop:prose-h3:leading-[44px]':
                          index === 1,
                      })}
                      key={index}
                      {...item}
                    />
                  ))}
                </div>
              )}
              {buttons && buttons.length > 0 && (
                <div className='mt-6 flex items-center py-4'>
                  <hr className={buttonLineStyles} />
                  {buttons.map((button, index) => (
                    <Button
                      key={index}
                      {...button}
                      className='whitespace-nowrap px-10 font-medium text-interface-700'
                      variant={EButtonVariant.Text}
                      theme={EButtonTheme.Primary}
                      size={EButtonSize.Large}
                    >
                      {button.children}
                    </Button>
                  ))}
                  <hr className={buttonLineStyles} />
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </section>
  );
};
