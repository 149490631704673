import { MediaAboutUsRow } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { useRouter } from 'next/router';
import React from 'react';

import useFetchNews from '../../../hooks/useWPposts';
import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type TLibraryCardRowWpConnected = Extract<
  Required<Section>['rows'][number],
  { blockType: 'media-about-us-row-wp' }
>;

const MediaAboutUsRowWPConnected: React.FC<
  TLibraryCardRowWpConnected
> = props => {
  const { newsApiDomain, newsApiEndpoint, buttons } = props;

  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');

  const { newsPosts, error, loading } = useFetchNews(
    newsApiDomain,
    newsApiEndpoint,
    false,
  );

  if (error) {
    return null;
  }

  return (
    <MediaAboutUsRow
      newsPosts={newsPosts}
      buttons={toCardButtons(buttons, currentLocale, appDispatch)}
      isLoading={loading}
    />
  );
};

export default MediaAboutUsRowWPConnected;
