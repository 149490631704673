import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { ENewsCardSize, TNewsCardProps } from '../NewsCard';

export const MediaAboutCard: React.FC<TNewsCardProps> = props => {
  const { title, link, cover, cardSize = ENewsCardSize.S, className } = props;

  const renderLink = link.href;

  if (!renderLink) {
    return null;
  }

  return (
    <Link
      {...link}
      // These news are not corporate and are always posted on foreign sites.
      target='_blank'
      className={clsx(
        'group flex h-auto min-h-[304px] min-w-[304px] shrink grow basis-[304px] flex-col justify-between rounded-3xl bg-surface-100 p-6 tablet:p-8',
        className,
        cardSize === ENewsCardSize.S && 'tablet:col-span-1',
        cardSize === ENewsCardSize.L &&
          'tablet:col-span-2 desktop:col-span-1 desktop:row-span-2',
      )}
    >
      {title ? (
        <h3
          style={{ letterSpacing: '-0.03rem' }}
          className='text-3xl font-normal leading-[32px] text-interface-1000 transition-opacity group-hover:opacity-80'
        >
          {title}
        </h3>
      ) : null}
      {cover && cover.src && (
        <div className='flex h-[72px] w-full items-end '>
          <img
            width={160}
            height={40}
            className='max-h-[40px] w-auto min-w-[140px] max-w-[180px] object-contain object-left opacity-35 grayscale transition-opacity group-hover:opacity-100 rtl:object-right'
            alt={cover.alt}
            src={cover.src}
            loading='lazy'
          />
        </div>
      )}
    </Link>
  );
};
